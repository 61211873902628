import * as React from 'react';
import omit from 'omit.js';
import classNames from 'classnames';
import FieldForm, { List } from 'rc-field-form';
import { ConfigContext } from '../config-provider';
import { FormContext } from './context';
import { useForm } from './util';
import { SizeContextProvider } from '../config-provider/SizeContext';
const InternalForm = (props, ref) => {
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const { form, colon, name, labelAlign, labelCol, wrapperCol, prefixCls: customizePrefixCls, hideRequiredMark, className = '', layout = 'horizontal', size, } = props;
    const prefixCls = getPrefixCls('form', customizePrefixCls);
    const formClassName = classNames(prefixCls, {
        [`${prefixCls}-${layout}`]: true,
        [`${prefixCls}-hide-required-mark`]: hideRequiredMark,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    const formProps = omit(props, [
        'prefixCls',
        'className',
        'layout',
        'hideRequiredMark',
        'wrapperCol',
        'labelAlign',
        'labelCol',
        'colon',
    ]);
    const [wrapForm] = useForm(form);
    wrapForm.__INTERNAL__.name = name;
    React.useImperativeHandle(ref, () => wrapForm);
    return (<SizeContextProvider size={size}>
      <FormContext.Provider value={{
        name,
        labelAlign,
        labelCol,
        wrapperCol,
        vertical: layout === 'vertical',
        colon,
    }}>
        <FieldForm id={name} {...formProps} form={wrapForm} className={formClassName}/>
      </FormContext.Provider>
    </SizeContextProvider>);
};
const Form = React.forwardRef(InternalForm);
export { useForm, List };
export default Form;
